/*noinspection CssInvalidAtRule*/
@tailwind base;
/*noinspection CssInvalidAtRule*/
@tailwind components;
/*noinspection CssInvalidAtRule*/
@tailwind utilities;

body {
  font-family: 'Barlow', sans-serif;
}

h1 {
  @apply text-2xl font-bold;
}

h2 {
  @apply text-xl font-bold;
}

h3 {
  @apply text-lg font-bold;
}

h4 {
  @apply font-bold;
}

a {
  @apply text-blue-500 cursor-pointer;
}

.backdrop {
  background: rgba(.2, .2, .2, .3);
}
